// @flow

import React, { Component } from "react";
import { Card, Icon, Image } from 'semantic-ui-react'
import styles from "../App.module.css";
import defaultImage from "../../assets/BackgroundGAPIVertical.png";

class NewsProperties extends Component {

	constructor(props) {
		super(props)
	}

	render() {

		let {news, mobile} = this.props;

		return (
            
            <div className={mobile?styles.newsPropertiesMobile:styles.newsProperties}>
				<img src={news.image?news.image:defaultImage} className={styles.newsPortraitImage}></img>
                {/*<p style={{fontWeight: "bolder", paddingTop: "10px", margin: "3px"}}>Fecha de publicación</p>
                <p>{news.date}</p>*/}
            </div>
		);

	}

}

export default NewsProperties;
