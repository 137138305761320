// @flow

import React, { Component } from "react";
import { Card, Icon, Image } from 'semantic-ui-react'
import styles from "../App.module.css";
import PerfectScrollbar from 'react-perfect-scrollbar';
import NewsProperties from "./NewsProperties";

class NewsDetail extends Component {

	constructor(props) {
		super(props)
	}

	render() {

		let {news, openNew, language, mobile} = this.props;

		return (
            
            <div className={styles.newsDetailBackground}>
				{news &&
                <div className={styles.newsDetailBox}>
					<div className={styles.newsHeader}>
						<span>{news.briefDescription[language]}</span>
						<span style={{cursor: "pointer"}} onClick={() => openNew(news.Id, false)}>X</span>
					</div>
					<div style={{display: "flex", width: "100%", height: "92%", flexDirection: mobile?"column":"row"}}>
						<NewsProperties mobile={mobile} news={news}/>
						<PerfectScrollbar style={{width: news.image&&!mobile?"65%":"100%"}}>
							<p className={styles.newsTitle}>{news.title[language]}</p>
							<div className={styles.newsContent} dangerouslySetInnerHTML={{__html: news.content[language].join('')}}></div>
							<p style={{paddingRight: "25px", width: "100%", textAlign: "right", fontSize: "18px"}}>Fecha de publicación: {news.date}</p>
						</PerfectScrollbar>
					</div>
				</div>}
            </div>
		);

	}

}

export default NewsDetail;
