// @flow

import React, { Component } from "react";
import styles from "../App.module.css";
import profile from "../../assets/profile.PNG";
import familia from "../../assets/familia.png";
import paula from "../../assets/paula.png";
import letras from "../../assets/boca.png";
import adolescente from "../../assets/adolescente.png";
import 'pure-react-carousel/dist/react-carousel.es.css';

class PsicoClinica extends Component {

    constructor(props) {
		super(props);
	}

	render() {

        const psicoAdulta =
            <span>
                <p>Desde el ámbito de la psicología, mejoramos la situación de malestar emocional de la persona, dotándola de estrategias para reestablecer su estabilidad y equilibrio.</p>
                <p>Proponemos un espacio agradable, de escucha, reflexión y acompañamiento ante momentos o situaciones difíciles de superar o elaborar. Potenciamos el autoconocimiento y los recursos propios de cada persona.</p>
                <p>Después de una primera visita, donde recogemos la demanda, realizamos unas sesiones de valoración y determinamos si es conveniente el inicio de un tratamiento, siempre teniendo en cuenta las particularidades y momento vital de la persona.</p>
                {/*<p style={{paddingBottom: "20px"}}>Ofrecemos también asesoramiento y terapia familiar, para modificar dinámicas disfuncionales y mejorar las relaciones interpersonales entre todos los miembros.</p>*/}

                <p>La atención que ofrecemos a los adultos parte de una escucha personalizada que permite a cada persona entender la causa de sus síntomas o malestar emocional, encontrar diferentes formas de respuesta o soluciones y conseguir equilibrio y bienestar.</p>
                
                <p>Algunas de las dificultades y trastornos que abordamos son:</p>
                <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                    · Depresión.
                    <br />· Fobias.
                    <br />· Obsesiones.
                    <br />· Ansiedad.
                    <br />· Problemas de relación social.
                    <br />· Anorexia y bulimia.
                    <br />· Trastornos corporales de origen psíquico.
                    <br />· Duelos por pérdidas, separación, etc.
                    <br />
                </p>
            </span>
        ;

        const psicoAdultaCatala =
            <span>
                <p>Des de l'àmbit de la psicologia, millorem la situació de malestar emocional de la persona, dotant-la d'estratègies per restablir-ne l'estabilitat i l'equilibri.</p>
                <p>Proposem un espai agradable, d'escolta, reflexió i acompanyament davant de moments o situacions difícils de superar o elaborar. Potenciem l´autoconeixement i els recursos propis de cada persona.</p>
                <p>Després d´una primera visita, on recollim la demanda, realitzem unes sessions de valoració i determinem si és convenient l´inici d´un tractament, sempre tenint en compte les particularitats i moment vital de la persona.</p>

                <p>L'atenció que oferim als adults parteix d'una escolta personalitzada que permet a cada persona entendre la causa dels símptomes o el malestar emocional, trobar diferents formes de resposta o solucions i aconseguir equilibri i benestar.</p>
                
                <p>Algunes de les dificultats i trastorns que abordem són:</p>
                <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                    · Depressió.
                    <br />· Fòbies.
                    <br />· Obsessions.
                    <br />· Ansietat.
                    <br />· Problemes de relació social.
                    <br />· Anorèxia i bulímia.
                    <br />· Trastorns corporals d'origen psíquic.
                    <br />· Dols per pèrdues, separació, etc.
                    <br />
                </p>
            </span>
        ;

        const psicoAdolescente =
        <span>
            <p>Los síntomas o dificultades que presentan niños, niñas y adolescentes, se tratan individualmente, ofreciendo acompañamiento y asesoramiento a la familia.</p>
            <p>En ocasiones, previo consentimiento de la familia, se establece contacto con la escuela y otros profesionales, para beneficio del tratamiento.</p>
            <p>Algunas de las dificultades y trastornos que abordamos en este ámbito de acción son:</p>
            <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                · Fobias.
                <br />· Ansiedad.
                <br />· Depresión.
                <br />· Anorexia y bulimia.
                <br />· Obsesiones.
                <br />· Trastorno del espectro autista (TEA).
                <br />· Déficit de atención e hiperactividad.
                <br />· Comportamientos impulsivos.
                <br />· Dificultades en las relaciones sociales.
                <br />· Trastornos del sueño.
                <br />· Enuresis y encopresis.
                <br />
            </p>
            <p>Los profesionales de GAPI valoramos la necesidad de establecer un vínculo estrecho con el niño, la niña o el/la adolescente, para garantizar la implicación en el tratamiento, teniendo en cuenta que habitualmente es otro u otros miembros de la familia quien realiza la demanda de ayuda. Se le ofrece un espacio donde se sienta cómodo/a para hablar y reflexionar sobre sus emociones, sentimientos, pensamientos, preocupaciones, miedos, etc.</p>
        </span>
        ;

        const psicoAdolescenteCatala =
        <span>
            <p>Els símptomes o dificultats que presenten nens, nenes i adolescents, es tracten individualment, oferint acompanyament i assessorament a la família.</p>
            <p>De vegades, amb el consentiment previ de la família, s'estableix contacte amb l'escola i altres professionals, per a benefici del tractament.</p>
            <p>Algunes de les dificultats i els trastorns que abordem en aquest àmbit d'acció són:</p>
            <p style={{paddingLeft: "40px", textAlign: "left", fontWeight: "bold"}}>
                · Fòbies.
                <br />· Ansietat.
                <br />· Depressió.
                <br />· Anorèxia i bulímia.
                <br />· Obsessions.
                <br />· Trastorn de l'espectre autista (TEA).
                <br />· Dèficit datenció i hiperactivitat.
                <br />· Comportaments impulsius.
                <br />· Dificultats en les relacions socials.
                <br />· Trastorns de la son.
                <br />· Enuresi i encopresi.
                <br />
            </p>
            <p>Els professionals de GAPI valorem la necessitat d'establir un vincle estret amb el nen, la nena o l'adolescent, per a garantir la implicació en el tractament, tenint en compte que habitualment és un altre o altres membres de la família qui fa la demanda d'ajuda. Se'ls ofereix un espai on se sentin còmodes per a parlar i reflexionar sobre les seves emocions, sentiments, pensaments, preocupacions, pors, etc.</p>
        </span>
        ;

        const psicoFamilia = 
            <span>
                <p>Realizamos acompañamiento y asesoramiento a las familias, para que puedan mejorar ciertas dinámicas que afectan a todos sus miembros, adquirir habilidades para resolver conflictos, llegar a comprenderse mejor, apoyarse emocionalmente, etc.</p>
                <p>Fomentamos los propios recursos de la familia para llegar a establecer cambios y facilitar el crecimiento personal y familiar.</p>
            </span>
        ;

        const psicoFamiliaCatala = 
            <span>
                <p>Realitzem acompanyament i assessorament a les famílies, perquè puguin millorar certes dinàmiques que afecten tots els seus membres, adquirir habilitats per resoldre conflictes, arribar a comprendre's millor, recolzar-se emocionalment, etc.</p>
                <p>Fomentem els propis recursos de la família per arribar a establir canvis i facilitar-ne el creixement personal i familiar.</p>
            </span>
        ;


        let {mobile, language} = this.props;
        
		return (
            <div>
                <div id="psico" className={styles.bloqueEspecialidad}>
                    <div style={{flexDirection: mobile?"column":"row", display: "flex"}}>
                        <img src={profile} style={{height: mobile?"45vh":"90vh", width: mobile?"100%":"50%", objectFit: "cover"}}></img>
                        <div className={mobile?styles.textoEspecialidadMobile:styles.textoEspecialidad}>
                            <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", marginBottom: "3vw"}}>
                                <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.subTitle}>{language==0?"Psicologia":"Psicología"}</p>
                                <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.title}>{language==0?"Adults":"Adultos"}</p>
                                <div style={{marginRight: "auto", marginLeft: "auto"}} className={styles.separationLinePersonCard}></div>
                            </div>
                            {language==0?psicoAdultaCatala:psicoAdulta}
                        </div>
                    </div>
                </div>
                <div className={styles.bloqueEspecialidad}>
                    <div style={{flexDirection: mobile?"column":"row", display: "flex"}}>
                        {mobile && <img src={adolescente} style={{height: mobile?"45vh":"90vh", width: mobile?"100%":"50%", objectFit: "cover"}}></img>}
                        <div className={mobile?styles.textoEspecialidadMobile:styles.textoEspecialidad}>
                            <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", marginBottom: "3vw"}}>
                                <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.subTitle}>{language==0?"Psicologia":"Psicología"}</p>
                                <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.title}>{language==0?"Infància i adolescència":"Infancia y adolescencia"}</p>
                                <div style={{marginRight: "auto", marginLeft: "auto"}} className={styles.separationLinePersonCard}></div>
                            </div>
                            {language==0?psicoAdolescenteCatala:psicoAdolescente}
                            
                        </div>
                        {!mobile && <img src={adolescente} style={{height: mobile?"45vh":"90vh", width: mobile?"100%":"50%", objectFit: "cover"}}></img>}
                    </div>
                </div>
                {/*<div style={{backgroundColor: "#ffffff", width: "100vw"}}>
                    <div style={{flexDirection: "row", display: "flex"}}>
                        <img src={adulto} style={{minHeight: "50vw", height: "50vw", width: "50vw", minWidth: "50vw", objectFit: "cover"}}></img>
                        <div style={{height: "50vw", padding: "4vw"}} className={styles.text}>
                            <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", marginBottom: "3vw"}}>
                                <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.subTitle}>Psicología</p>
                                <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.title}>Edad adulta</p>
                                <div style={{marginRight: "auto", marginLeft: "auto"}} className={styles.separationLinePersonCard}></div>
                            </div>
                            
                            <p>La atención que ofrecemos a los adultos parte de una escucha personalizada que permite a cada persona entender la causa de sus síntomas o malestar emocional, encontrar diferentes formas de respuesta o soluciones y conseguir equilibrio y bienestar.</p>
                            
                            <p>Algunas de las dificultades y trastornos que abordamos son:</p>
                            <p style={{paddingLeft: "40px", textAlign: "left"}}>
                                · Depresión.
                                <br />· Fobias.
                                <br />· Obsesiones.
                                <br />· Ansiedad.
                                <br />· Problemas de relación social.
                                <br />· Anorexia y bulimia.
                                <br />· Trastornos corporales de origen psíquico.
                                <br />· Duelos por pérdidas, separación, etc.
                                <br />· Etc.
                                <br />
                            </p>
                        </div>
                    </div>
        </div>*/}
                <div className={styles.bloqueEspecialidad}>
                    <div style={{flexDirection: mobile?"column":"row", display: "flex"}}>
                        <img src={familia} style={{height: mobile?"45vh":"90vh", width: mobile?"100%":"50%", objectFit: "cover"}}></img>
                        <div className={mobile?styles.textoEspecialidadMobile:styles.textoEspecialidad}>
                            <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", marginBottom: "3vw"}}>
                                <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.subTitle}>{language==0?"Psicologia":"Psicología"}</p>
                                <p style={{marginRight: "auto", marginLeft: "auto", textAlign: "center"}} className={styles.title}>{language==0?"Famílies":"Familias"}</p>
                                <div style={{marginRight: "auto", marginLeft: "auto"}} className={styles.separationLinePersonCard}></div>
                            </div>
                            {language==0?psicoFamiliaCatala:psicoFamilia}
                            
                        </div>
                    </div>
                </div>
            </div>        
		);

	}

}

export default PsicoClinica;
