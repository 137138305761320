// @flow

import React, { Component } from "react";
import banner1 from "../../assets/banner1.jpg";
import banner2 from "../../assets/banner2.jpg";
import banner3 from "../../assets/banner3.png";
import styles from "../App.module.css";
import PerfectScrollbar from 'react-perfect-scrollbar';
import puzzle from "../../assets/puzzle.png";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Banner from "./Banner.js";
import buttonsCarousel from "../../assets/buttonsCarousel.png";
import mapboxgl, {Marker} from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Recaptcha from 'react-recaptcha';
import emailjs from '@emailjs/browser'
import { Checkbox } from "@mui/material";
import Footer from "./Footer.js";

class Contact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lng: 2.1805860872949565,
            lat: 41.41746787754965,
            zoom: 16,
            name: "",
            surname: "",
            email: "",
            phone: "",
            asunto: "",
            body: "",
            email: "",
            isVerified: false,
            mailSent: false,
            politica: false
        };
        this.mapContainer = React.createRef();
    }

    componentDidMount() {
        const { lng, lat, zoom } = this.state;
        const map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            width: "100%",
            center: [lng, lat],
            zoom: zoom
        });

        const el = document.createElement('div');
        el.className = styles.marker;
        el.onclick = () => {
            window.open("https://www.google.es/maps/place/GAPI+Gabinet+de+Psicologia/@41.4182057,2.1803359,18.43z/data=!4m5!3m4!1s0x12a4a2d4f7b6aaad:0xac703597c060a3d8!8m2!3d41.4181754!4d2.1812107?hl=es", "_blank");
        }

        new mapboxgl.Marker(el).setLngLat([lng, lat]).addTo(map);
    }

    recaptchaLoaded() {
        console.log('capcha successfully loaded');
      }
    
      handleSubscribe() {
        if (this.state.isVerified) {
          alert('You have successfully subscribed!');
        } else {
          alert('Please verify that you are a human!');
        }
      }
    
      verifyCallback(response) {
        if (response) {
          this.setState({
            isVerified: true
          })
        }
      }

    onNameChange(ev) {
        this.setState({
            name: ev.currentTarget.value
        });
    }

    onSurnameChange(ev) {
        this.setState({
            surname: ev.currentTarget.value
        });
    }

    onPhoneChange(ev) {
        this.setState({
            phone: ev.currentTarget.value
        });
    }

    onEmailChange(ev) {
        this.setState({
            email: ev.currentTarget.value
        });
    }

    onAsuntoChange(ev) {
        this.setState({
            asunto: ev.currentTarget.value
        });
    }

    onBodyChange(ev) {
        this.setState({
            body: ev.currentTarget.value
        });
    }

    onPoliticaChange() {
        this.setState({
            politica: !this.state.politica
        });
    }

    sendEmail(){
        var setNewState = (state) => this.setState(state);

        if (this.state.isVerified){
            var templateParams = {
                nombre: this.state.name + " " + this.state.surname,
                mensaje: this.state.body,
                asunto: this.state.asunto,
                telf: this.state.phone,
                email: this.state.email,
                send_to: "mj.gapi@hotmail.com"
            };

            emailjs.send("service_g6r7rah","template_tpnz03g", templateParams, "TKnsmzssVeKC9FHw4").then(function(response) {
                console.log('SUCCESS!', response.status, response.text);
                setNewState({mailSent: true});
             }, function(error) {
                console.log('FAILED...', error);
             });
        }
    }

	render() {

        const { lng, lat, zoom, name, surname, phone, asunto, body, isVerified, mailSent, email, politica } = this.state;
        let {mobile, language} = this.props;
        mapboxgl.accessToken = 'pk.eyJ1Ijoia2FzdGhhbiIsImEiOiJjanJobzRkNzAwMTM0NGFwamZwYTg3eGk0In0.b68M-Tiq-wcjYS4Y4-rxUw';
		
		return (
            <PerfectScrollbar>
                <div className={styles.AppBodyCont}>
                    <p style={{marginBottom: "20px"}}></p>
                    <p className={mobile?styles.titleMobile:styles.title} style={{marginLeft: "4vw"}}>{language==0?"Contacte":"Contacto"}</p>
                    <div className={styles.separationLine} style={{marginLeft: "2vw"}}></div>
                    <p style={{marginBottom: "20px"}}></p>
                    <div>
                        <div style={{display: "flex", flexDirection: mobile?"column":"row"}}>
                            <div ref={this.mapContainer} className={mobile?styles.mapContainerMobile:styles.mapContainer} />
                            <div className={mobile?styles.emailFormMobile:styles.emailForm}>
                                <p style={{fontSize: "17px", fontWeight: "bolder", textAlign: "left", width: "100%", paddingBottom: "10px"}}>{language==0?"Envia'ns un e-mail":"Envíanos un e-mail"}</p>
                                <Box
                                    component="form"
                                    sx={{
                                        '& .MuiTextField-root': { m: 1, width: '100%'},
                                    }}
                                    noValidate
                                    autoComplete="off"
                                    >
                                    <div style={{display: "flex", flexDirection: "row"}}> 
                                        <TextField
                                        id="outlined-multiline-flexible"
                                        label={language==0?"Nom":"Nombre"}
                                        value={name}
                                        required
                                        onChange={(ev) => this.onNameChange(ev)}
                                        />
                                        <TextField
                                        id="outlined-multiline-flexible"
                                        label={language==0?"Cognom":"Apellido"}
                                        required
                                        value={surname}
                                        onChange={(ev) => this.onSurnameChange(ev)}
                                        />
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <TextField
                                        id="outlined-multiline-flexible"
                                        label={language==0?"Telèfon":"Teléfono"}
                                        required
                                        value={phone}
                                        onChange={(ev) => this.onPhoneChange(ev)}
                                        />
                                        <TextField
                                        id="outlined-multiline-flexible"
                                        label={language==0?"E-mail":"E-mail"}
                                        required
                                        value={email}
                                        onChange={(ev) => this.onEmailChange(ev)}
                                        />
                                    </div>
                                    <div style={{paddingRight: "16px"}}>
                                        <TextField
                                        id="outlined-multiline-flexible"
                                        label={language==0?"Assumpte del correu":"Asunto del correo"}
                                        value={asunto}
                                        required
                                        fullWidth
                                        onChange={(ev) => this.onAsuntoChange(ev)}
                                        />
                                    </div>
                                    <div style={{paddingRight: "16px"}}>
                                        <TextField
                                        id="outlined-multiline-flexible"
                                        label={language==0?"Contingut del correu":"Contenido del correo"}
                                        multiline
                                        fullWidth
                                        required
                                        rows={4}
                                        value={body}
                                        onChange={(ev) => this.onBodyChange(ev)}
                                        />
                                    </div>
                                    <div style={{margin: "8px", display: "flex", flexDirection: "row"}}>
                                        <Checkbox onChange={() => this.onPoliticaChange()}></Checkbox>
                                        <span style={{marginTop: "10px"}}>Acepto la <a href="https://www.iubenda.com/privacy-policy/15756432" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Política de Privacidad ">Política de Privacidad</a></span>
                                    </div>
                                    {mobile?
                                    <div style={{margin: "8px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                                        <Recaptcha
                                            sitekey="6LduWpEcAAAAADOe8YtsQ4LBER-px3Oq6Eaywadi"
                                            render="explicit"
                                            onloadCallback={() => this.recaptchaLoaded()}
                                            verifyCallback={(response) => this.verifyCallback(response)}
                                            
                                        />
                                        <p style={{marginBottom: "20px"}}></p>
                                        <Button variant="contained" fullWidth disabled={!isVerified||mailSent||!politica||name.length==0||surname.length==0||asunto.length==0||body.length==0||phone.length==0} onClick={()=>this.sendEmail()}>
                                            Enviar
                                        </Button>
                                        <p style={{marginBottom: "20px"}}></p>
                                    </div>
                                    :
                                    <div style={{margin: "8px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <Recaptcha
                                            sitekey="6LduWpEcAAAAADOe8YtsQ4LBER-px3Oq6Eaywadi"
                                            render="explicit"
                                            onloadCallback={() => this.recaptchaLoaded()}
                                            verifyCallback={(response) => this.verifyCallback(response)}
                                        />
                                        <Button variant="contained" disabled={!isVerified||mailSent||!politica||name.length==0||surname.length==0||asunto.length==0||body.length==0||phone.length==0||email.length==0} onClick={()=>this.sendEmail()}>
                                            Enviar
                                        </Button>
                                    </div>
                                    }
                                </Box>
                                {mailSent && <p style={{color: "green", textAlign: "center", fontWeight: "bolder", fontSize: "17px", marginTop: "20px"}}>El correo se ha enviado correctamente.</p>}
                            </div>
                        </div>
                        {/* <div style={{width: "100vw", backgroundColor: "#e8f8ff"}}>
                        <p style={{height: "auto", textAlign: "center", paddingBottom: "10px", paddingTop: "10px"}}>{language==0?"Gabinet de Psicologia GAPI • Pg. de Maragall, 70, 08041 Barcelona • T. 93 349 29 54 - M. 675 73 00 15 • gapi@gapipsicolegs.com":"Gabinete de Psicología GAPI • Pg. de Maragall, 70, 08041 Barcelona • T. 93 349 29 54 - M. 675 73 00 15 • gapi@gapipsicolegs.com"}</p>
                        </div> */}
                        <Footer language={language} top={"-30px"} bgColor={"white"}></Footer>
                    </div>
                </div>
            </PerfectScrollbar>
		);

	}

}

export default Contact;
