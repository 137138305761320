// @flow

import React, { Component } from "react";
import styles from "../App.module.css";
import PerfectScrollbar from 'react-perfect-scrollbar';
import MJ from "../../assets/MJ.jpg";
import buttonsCarousel from "../../assets/buttonsCarousel.png";

class TarjetaPersona extends Component {

    constructor(props) {
		super(props);
	}

	render() {
		
		return (
            
            <div className={styles.personCard}>
                <div style={{display: "contents"}}>
                    <img src={this.props.foto} style={{borderRadius: "50%", marginRight: "auto", marginLeft: "auto", marginTop: "2vw", border: "2px solid #87cdf5", boxShadow: "0px 0px 6px 6px #cde8f7", padding: "2px"}}></img>
                    <div style={{marginRight: "auto", marginLeft: "auto", width: "90%", position: "relative", top: "-0.5vw"}}>
                        <p className={styles.personCardTitle} style={{textAlign: "center", backgroundColor: "#f5f9fb", borderRadius: "20px", boxShadow: "0px 0px 3px 3px #f5f9fb"}}>{this.props.nombre}</p>
                        <div className={styles.separationLinePersonCard}></div>
                    </div>
                </div>
                
                <div className={styles.textImage}>
                    <div className={styles.textCV}>
                        {this.props.contenido}
                    </div>
                </div>
            </div>
                            
		);

	}

}

export default TarjetaPersona;
