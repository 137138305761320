// @flow

import React, { Component } from "react";
import styles from "../App.module.css";

class Banner extends Component {

    constructor(props) {
        super(props);
    }

	render() {
        
        const {image, title, description} = this.props;

		return (
			<div className={styles.bannerSlide}>
                <img src={image} className={styles.imageBanner}></img>
                <div className={styles.bannerText}>
                    <p className={styles.bannerTitle}>{title}</p>
                    <p className={styles.bannerDescription}>{description}</p>
                </div>
			</div>
		);

	}

}

export default Banner;
