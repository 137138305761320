// @flow

import React, { Component } from "react";
import styles from "../App.module.css";
import BA1 from "../../assets/BarcelonaActiva1.png";
import BA2 from "../../assets/BarcelonaActiva2.png";

class Footer extends Component {

    constructor(props) {
        super(props);

    }

	render() {
		
        let {language, top, bgColor, padding} = this.props;

		return (
            <div style={{position: "relative", top: top, backgroundColor: bgColor, paddingTop: padding}}>
                <div style={{width: "100vw", flexDirection: "row", display: "flex", justifyContent: "space-between", alignItems: "flex-end"}}>
                    <div style={{maxWidth: "60vw"}}>
                        <p style={{fontSize: "13px", marginLeft: "10px", fontWeight: "bold"}}>{language==0?"Amb el suport de l'Ajuntament de Barcelona":"Con el soporte del Ayuntamiento de Barcelona"}</p>
                        <img src={BA1} style={{maxWidth: "300px", width: "-webkit-fill-available", marginBottom: "-6px", marginTop: "-10px"}} title={"Amb el suport de l'Ajuntament de Barcelona"} alt={"Amb el suport de l'Ajuntament de Barcelona"}></img>
                    </div>
                    <div>
                        <img src={BA2} style={{maxWidth: "30vw", width: "150px", marginRight: "10px", marginTop: "-10px"}} title={"Amb el suport de l'Ajuntament de Barcelona"} alt={"Amb el suport de l'Ajuntament de Barcelona"}></img>
                    </div>
                </div>
                <div style={{width: "100vw", backgroundColor: "#e8f8ff"}}>
                    <p style={{height: "auto", textAlign: "center", paddingBottom: "10px", paddingTop: "10px"}}>{language==0?"Gabinet de Psicologia GAPI • Pg. de Maragall, 70, 08041 Barcelona • T. 93 349 29 54 - M. 675 73 00 15 • gapi@gapipsicolegs.com":"Gabinete de Psicología GAPI • Pg. de Maragall, 70, 08041 Barcelona • T. 93 349 29 54 - M. 675 73 00 15 • gapi@gapipsicolegs.com"}</p>
                </div>
            </div>
		);

	}

}

export default Footer;